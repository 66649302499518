<template>
  <div class="plan-cycle-box" v-scroll-reveal>
    <div class="content-box">
      <div class="plan-title2">
        <div class="flex title2">
          <img class="titleIcon1" src="../assets/img/titleIcon1.png" alt="" />
          <span>{{ cycleData.title }}</span>
          <img
            class="titleIcon1 rotate180"
            src="../assets/img/titleIcon1.png"
            alt=""
          />
        </div>
        <div class="flex mes2">
          <img src="../assets/img/titleIcon2.png" alt="" />
        </div>
      </div>
      <div
        class="plan-introduce"
        style="text-align: center"
        v-if="cycleData.introduce"
      >
        {{ cycleData.introduce }}
      </div>
      <div class="plan-cycle">
        <div class="flex plan-cycle-item">{{ cycleData.list[0] }}</div>
        <div class="flex plan-cycle-item">{{ cycleData.list[1] }}</div>
        <div class="flex plan-cycle-item plan-cycle-item2">
          {{ cycleData.list[2] }}
        </div>
        <div class="flex plan-cycle-item">{{ cycleData.list[3] }}</div>
        <div class="flex plan-cycle-item">{{ cycleData.list[4] }}</div>
      </div>
      <img class="cycle_sanjiao" src="../assets/img/cycle_sanjiao.png" alt="" />
      <div class="plan-cycle2">
        <div class="plan-cycle2-div">
          {{ cycleData.list2[0].cycle }}
          <span>{{ cycleData.list2[0].introduce }}</span>
        </div>
        <img
          class="plan-cycle2-icon"
          src="../assets/img/plan_icon10.png"
          alt=""
        />
        <div class="plan-cycle2-div">
          {{ cycleData.list2[1].cycle }}
          <span>{{ cycleData.list2[1].introduce }}</span>
        </div>
        <img
          class="plan-cycle2-icon"
          src="../assets/img/plan_icon10.png"
          alt=""
        />
        <div class="plan-cycle2-div">
          {{ cycleData.list2[2].cycle }}
          <span>{{ cycleData.list2[2].introduce }}</span>
        </div>
        <img
          class="plan-cycle2-icon"
          src="../assets/img/plan_icon10.png"
          alt=""
        />
        <div class="plan-cycle2-div">
          {{ cycleData.list2[3].cycle }}
          <span>{{ cycleData.list2[3].introduce }}</span>
        </div>
        <img
          class="plan-cycle2-icon"
          src="../assets/img/plan_icon10.png"
          alt=""
        />
        <div class="plan-cycle2-div">
          {{ cycleData.list2[4].cycle }}
          <span>{{ cycleData.list2[4].introduce }}</span>
        </div>
      </div>
      <div
        class="plan-cycle3"
        v-if="cycleData.list3 && cycleData.list3.length > 0"
      >
        <div
          class="plan-cycle3-item"
          v-for="(item, index) in cycleData.list3"
          :key="index"
        >
          <span>*</span>
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cycleMod",
  props: ["cycleData"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.content-box {
  width: 1200px;
  margin: 0 auto;
}
.plan-introduce {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  margin-bottom: 30px;
}
.plan-cycle-box {
  background-image: url("../assets/img/cycle_bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // padding-bottom: 46px;
  padding-top: 80px;
  height: 646px;
  // margin-bottom: 100px;
}

.plan-cycle {
  display: flex;
  justify-content: space-between;

  .plan-cycle-item {
    padding: 19px 32px;
    background-color: #fff;
    border-radius: 3px;
    min-width: 190px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
  }

  .plan-cycle-item2 {
    width: 357px;
    background-color: #2d43f2;
    color: #fff;
  }
}

.cycle_sanjiao {
  display: block;
  width: 28px;
  height: 13px;
  margin: 14px auto;
}

.plan-cycle2 {
  display: flex;
  height: 143px;
  border-radius: 3px;
  background-color: #fff;
  align-items: center;
  margin-bottom: 29px;
  .plan-cycle2-div {
    min-width: 200px;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    padding: 0 46px;
    span {
      font-size: 16px;
      margin-top: 9px;
      display: block;
      height: 42px;
    }
  }

  .plan-cycle2-icon {
    width: 28px;
    height: 28px;
  }
}

.plan-cycle3 {
  .plan-cycle3-item {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 14px;
    span {
      color: #545ee7;
    }
  }
}
</style>